









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardComponent from "@/commoncomponents/CardComponent.vue";
import { ScreenText } from "@/lang/ScreenText";
import paymentsModule from "@/store/modules/Payments/module";
import ToggleSwitch from "@/commoncomponents/ToggleSwitch.vue";
import APP_UTILITIES from "@/utilities/commonFunctions";
import { ToastType } from "@/Model/toastType";
import { CustomCheckoutOption } from "@/Model/payments/types";
import { updateCustomOptionStatus } from "@/services/payments/api";

@Component({
  components: {
    CardComponent,
    ToggleSwitch
  }
})
export default class CustomCheckoutDetail extends Vue {
  enabled = false;
  // Initial flag to indicate first render state
  private _firstRender = true;
  disableSwitch = false;

  name = "";

  @Prop({ required: true }) checkoutOption: CustomCheckoutOption | undefined;

  get checkoutName() {
    return this.checkoutOption ? this.checkoutOption.name : "";
  }

  get checkoutInstruction() {
    return this.checkoutOption ? this.checkoutOption.instructions : "";
  }

  mounted() {
    this._firstRender = false;
    const data = this.checkoutOption
      ? { ...this.checkoutOption }.isEnabled
      : false;
    this.enabled = data;
  }

  readonly screenText = new ScreenText();

  toggleEditCustomCheckout() {
    this.$emit("EditCustomCheckout");
  }

  onStatusUpdated() {
    this.$emit("OnStatusUpdated");
  }

  @Watch("enabled")
  async onEnabledChanged() {
    if (
      !this._firstRender &&
      this.checkoutOption &&
      this.checkoutOption.isEnabled !== this.enabled
    ) {
      try {
        this.disableSwitch = true;
        await updateCustomOptionStatus({
          isEnabled: this.enabled,
          customCheckoutId: this.checkoutOption.id
        });
        this.onStatusUpdated();
        APP_UTILITIES.showToastMessage(
          `Custom checkout is ${!this.enabled ? "inactive" : "active"}`,
          ToastType.Success
        );
      } catch {
        APP_UTILITIES.showToastMessage("Unexpected Error!", ToastType.Error);
        this.enabled = !this.enabled;
      } finally {
        this.disableSwitch = false;
      }
    }
  }
}
