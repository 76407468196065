


































import { Component, Vue, Watch } from 'vue-property-decorator';
import CardComponent from '@/commoncomponents/CardComponent.vue';
import { ScreenText } from '@/lang/ScreenText';
import paymentsModule from '@/store/modules/Payments/module';
import ToggleSwitch from '@/commoncomponents/ToggleSwitch.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Status } from '@/Model/shared/types';
import { getFormattedFeeDisplayValue } from '@/services/payments/helpers';
import { ToastType } from '@/Model/toastType';



@Component({
  components: {
    CardComponent,
    ToggleSwitch
  },
})


export default class FeeDetails extends Vue {

  readonly screenText = new ScreenText();
  enabled = this.isActive;

  get isActive(): boolean {
    return !!paymentsModule.customFee && paymentsModule.customFee.status == Status.Active;
  }

  get feeAmount(): string {
    let feeAmountString = '0';
    if (paymentsModule.customFee) {
      feeAmountString = getFormattedFeeDisplayValue(paymentsModule.customFee);
    }
    return feeAmountString;
  }

  get feeName(): string {
    return paymentsModule.customFee
      ? paymentsModule.customFee.name
      : '';
  }

  get taxRateDisplay(): string | null {
    return paymentsModule.customFee && paymentsModule.customFee.taxRate
      ? `${paymentsModule.customFee.taxRate.displayName} ${this.screenText.getScreenText('LABEL_TAX_RATE_JOINING_TEXT')} ${paymentsModule.customFee.taxRate.percentage}%`
      : null;
  }

  get disableSwitch(): boolean {
    return paymentsModule.isLoadingCustomFee || paymentsModule.isSavingCustomFee;
  }

  toggleEditCustomFee() {
    this.$emit('editCustomFee');
  }

  @Watch('enabled')
  async onEnabledChanged() {
    paymentsModule.changeCustomFeeStatus();
    await paymentsModule.updateCustomFee(paymentsModule.customFee!); //We can assert !null because the component wouldn't render in Payments.vue without

    if (this.isActive) {
      APP_UTILITIES.showToastMessage(this.screenText.getScreenText('ACTIVE_FEE_TOAST'), ToastType.Success);
    }
    else {
      APP_UTILITIES.showToastMessage(this.screenText.getScreenText('INACTIVE_FEE_TOAST'), ToastType.Success);
    }
  }
}

