































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import BaseModal from '@/popupcomponents/BaseModal.vue';
import { StateModalType } from '@/Model/payments/types';

@Component({
  components: {
    BaseModal
  }
})
export default class StateModal extends Vue {
  @PropSync('isVisible') isVisibleSync!: boolean;

  @Prop() config!: {
    title: string;
    description: string;
    type: StateModalType;
    cancelButtonLabel: string;
    confirmButtonLabel: string;
  };

  onCancel() {
    this.$emit('onClose');
  }

  onConfirm() {
    this.$emit('onConfirm');
  }

  get iconClass() {
    switch (this.config.type) {
      case StateModalType.WARNING:
        return 'fa-solid fa-triangle-exclamation fa-7x warning-icon';

      case StateModalType.ERROR:
        return 'fa-solid fa-trash fa-7x error-icon';

      default:
        return '';
    }
  }

  get btnClass() {
    switch (this.config.type) {
      case StateModalType.WARNING:
        return 'btn-primary';

      case StateModalType.ERROR:
        return 'btn-danger';

      default:
        return 'btn-primary';
    }
  }
}
