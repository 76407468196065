import { extend } from 'vee-validate';
import { required, min_value } from 'vee-validate/dist/rules';
import { ValidationRuleFunction } from 'vee-validate/dist/types/types';

export const urlRegex: RegExp = /^(https?:\/\/)?(w{3})?[-a-z0-9@:%._+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()@:%_+.~#?&/=]*)$/i;

export const priceGreaterThanZeroWithTaxRateValidator: ValidationRuleFunction = (price: number, { taxRate }: Record<string, any>) => {
  if (price === 0 && !!taxRate) {
    return false;
  }
  return true;
};

export function registerPriceRequiredValidator(message: string) {
  extend('price_required', {
    ...required,
    message
  });
}

export function requiredField(message: string) {
  extend('field_required', {
    ...required,
    message
  });
}


export function registerPriceMinValueValidator(message: string) {
  extend('price_min_value', {
    ...min_value,
    message
  });
}

export function registerPriceGreaterThanZeroWithTaxRateValidator(message: string) {
  extend('price_greater_than_zero_with_tax_rate', {
    params: ['taxRate'],
    validate: priceGreaterThanZeroWithTaxRateValidator,
    message
  });
}