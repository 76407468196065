








































































































































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import { VeeValidateProviderMode } from '@/Model/forms/types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { DropdownListItem } from '@/ui-components/dropdownListBx/types';
import { AmountUnitType } from '@/Model/payments/types';
import NotificationBox from '@/commoncomponents/NotificationBox.vue';
import { percentInputRequiredValidator } from '@/validators/payments/validators';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    DropdownList,
    EarlyRegistration,
    NotificationBox
  }
})
export default class EarlyRegistration extends Vue {
  @PropSync('discountUnitAmount', { type: String, required: true })
  discountUnitAmountSync!: string;
  @PropSync('earlyDiscountEnds', { required: true }) earlyDiscountEndsSync!:
    | number
    | null;
  @PropSync('earlyAmountValue', { required: true }) earlyAmountValueSync!:
    | number
    | null;
  @Prop({ required: true }) discountRuleListDropdown!: DropdownListItem[];

  @Prop()
  formRef!: InstanceType<typeof ValidationObserver>;

  readonly screenText = new ScreenText();
  readonly discountType = AmountUnitType;
  validationMode = VeeValidateProviderMode;

  created() {
    const message = this.getErrorMsgLabel(this.discountUnitAmountSync);
    this.registerValidators(message);
  }

  @Watch('discountUnitAmountSync', { deep: true })
  discountUnitAmountReference(): void {
    this.formRef.reset();
    const message = this.getErrorMsgLabel(this.discountUnitAmountSync);
    this.registerValidators(message);
  }

  private registerValidators(message: string = ''): void {
    percentInputRequiredValidator(message);
  }

  getErrorMsgLabel(discountAmount: string): string {
    return discountAmount === AmountUnitType.PERCENT_RATE
      ? this.screenText.getScreenText('RULES_PERCENT_ERROR')
      : this.screenText.getScreenText('AUTOMATIC_PAYMENTS_AMOUNT_ERROR');
  }

  onSelectSessionStart(value: DropdownListItem) {
    this.$emit('onChangeSessionStart', value);
  }

  onAmountUpdated() {
    // Validate that percentage amount doesn't go over 100%
    if (
      this.discountUnitAmountSync === AmountUnitType.PERCENT_RATE &&
      this.earlyAmountValueSync !== null &&
      this.earlyAmountValueSync > 100
    ) {
      this.earlyAmountValueSync = 100;
    }
  }
}
