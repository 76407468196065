























































































































import {
  Vue,
  Component,
  Ref,
  PropSync,
  Watch,
  Prop
} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { DropdownListOptions } from '@/ui-components/dropdownListBx/types';
import { VeeValidateProviderMode } from '@/Model/forms/types';
import { ProviderInstance } from 'vee-validate/dist/types/types';
import { percentInputRequiredValidator } from '@/validators/payments/validators';
import { AmountUnitType } from '@/Model/payments/types';

type CustomDropdownOptions = DropdownListOptions & { ruleAmount: string };

@Component({
  components: {
    ValidationProvider,
    DropdownList
  }
})
export default class MultiParticipantRules extends Vue {
  @Ref('nameObserverRef') readonly nameObserverRef?: ProviderInstance;
  @Prop({ required: true }) rules!: CustomDropdownOptions[];
  @PropSync('discountUnitAmount', { type: String, required: true })
  discountUnitAmountSync!: string;

  @Prop()
  formRef!: InstanceType<typeof ValidationObserver>;

  @Watch('discountUnitAmountSync', { deep: true })
  discountUnitAmountReference(): void {
    this.formRef.reset();
    const message = this.getErrorMsgLabel(this.discountUnitAmountSync);
    this.registerValidators(message);
  }

  readonly screenText = new ScreenText();
  readonly discountType = AmountUnitType;
  validationMode = VeeValidateProviderMode;

  get isAddRuleVisible() {
    return this.rules.length <= 10 - Number(this.rules[0].value);
  }

  getErrorMsgLabel(discountAmount: string): string {
    return discountAmount === AmountUnitType.PERCENT_RATE
      ? this.screenText.getScreenText('RULES_PERCENT_ERROR')
      : this.screenText.getScreenText('AUTOMATIC_PAYMENTS_AMOUNT_ERROR');
  }

  created() {
    const message = this.getErrorMsgLabel(this.discountUnitAmountSync);
    this.registerValidators(message);
  }

  private registerValidators(message: string = ''): void {
    percentInputRequiredValidator(message);
  }

  onAmountUpdated(rule: CustomDropdownOptions): void {
    // Validate that percentage amount doesn't goes over 100%
    if (this.discountUnitAmountSync === AmountUnitType.PERCENT_RATE) {
      this.$emit('onPercentageUpdate', rule);
    }
  }
}
