




















































































































import { ScreenText } from '@/lang/ScreenText';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import ToggleSwitch from '@/commoncomponents/ToggleSwitch.vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { urlRegex } from '@/validators/sessions/validators';
import { max, regex, required_if } from 'vee-validate/dist/rules';
import { VeeValidateProviderMode } from '@/Model/forms/types';
import { ProviderInstance } from 'vee-validate/dist/types/types';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import paymentsModule from '@/store/modules/Payments/module';
import { AddUpdateStorePolicyPayload, StorePolicy } from '@/Model/payments/types';
import APP_UTILITIES from '@/utilities/commonFunctions';
import NotificationBox from '@/commoncomponents/NotificationBox.vue';
import { NotificationBoxType } from '@/Model/notificationBox/types';
import { ToastType } from '@/Model/toastType';

@Component({
  components: {
    NotificationBox,
    ProgressButton,
    ToggleSwitch,
    ValidationObserver,
    ValidationProvider
  }
})

export default class Payments extends Vue {
  @Ref('enabledProviderRef') readonly enabledProviderRef?: ProviderInstance;
  @Ref('urlProviderRef') readonly urlProviderRef?: ProviderInstance;
  @Ref('formObserverRef') readonly formObserverRef?: InstanceType<typeof ValidationObserver>;

  private readonly accountId = Number(APP_UTILITIES.getCookie('accountId')) || 0;
  private readonly screenText = new ScreenText();
  readonly enabledProviderVid = 'enabledProvider';
  readonly urlMaxLength = 2083;
  readonly urlRules: Record<string, unknown> = {
    required_if: { target: this.enabledProviderVid, values: ['true'] },
    regex: urlRegex,
    max: { length: this.urlMaxLength }
  };
  readonly NotificationBoxType = NotificationBoxType;

  isValidForProgressButton = true;

  enabled = false;
  url = '';

  get policySwitchLabel(): string {
    return this.screenText.getScreenText('POLICY_SWITCH_LABEL');
  }

  get policyUrlLabel(): string {
    return this.screenText.getScreenText('POLICY_URL_LABEL');
  }

  get buttonPolicySave(): string {
    return this.screenText.getScreenText('BTN_POLICY_SAVE');
  }

  get policySavedSuccess(): string {
    return this.screenText.getScreenText('POLICY_SAVED_SUCCESS');
  }

  get translatedLoadingToProgressButtonState(): number {
    let buttonState = 0;

    if (!paymentsModule.isSavingStorePolicy && !paymentsModule.didSaveStorePolicyFail) {
      buttonState = 200;
    }
    else if (!paymentsModule.isSavingStorePolicy && paymentsModule.didSaveStorePolicyFail) {
      buttonState = 400;
    }

    return buttonState;
  }

  get showLoadingSpinner(): boolean {
    return paymentsModule.isFetchingStorePolicy;
  }

  get disableSwitch(): boolean {
    return paymentsModule.isFetchingStorePolicy || paymentsModule.isSavingStorePolicy;
  }

  get initialValue(): StorePolicy {
    return paymentsModule.storePolicy;
  }

  get fetchFailed(): boolean {
    return paymentsModule.didFetchStorePolicyFail;
  }

  get fetchFailedErrorMessage(): string {
    return this.screenText.getScreenText('POLICY_FETCH_FAILED');
  }

  get saveFailed(): boolean {
    return paymentsModule.didSaveStorePolicyFail;
  }

  get saveFailedErrorMessage(): string {
    return this.screenText.getScreenText('POLICY_SAVED_FAILED');
  }

  private registerValidators() {
    extend('required_if', {
      ...required_if,
      message: this.screenText.getScreenText('POLICY_URL_ERROR')
    });
    extend('regex', {
      ...regex,
      message: this.screenText.getScreenText('POLICY_URL_ERROR')
    });
    extend('max', {
      ...max,
      message: this.screenText.getScreenText('CHAR_LIMIT_MAXIMUM').replace('{length}', `${this.urlMaxLength}`)
    });
  }

  created() {
    this.registerValidators();
    paymentsModule.fetchStorePolicy(this.accountId);
  }

  @Watch('enabled')
  onEnabledChanged(enabled: boolean) {
    if (!enabled && !this.saveFailed) {
      this.resetUrlInput().then(() => {
        this.formObserverRef && this.formObserverRef.handleSubmit(this.saveStorePolicy);
      });
    }
  }

  @Watch('initialValue')
  onInitialValueChanged() {
    this.resetEnabledInput();
    this.resetUrlInput();
  }

  private async resetEnabledInput() {
    this.enabled = this.initialValue.enabled;
    if (this.enabledProviderRef) {
      await this.enabledProviderRef.validateSilent();
      this.enabledProviderRef.reset();
    }
  }

  private async resetUrlInput() {
    this.url = this.initialValue.url;
    if (this.urlProviderRef) {
      await this.urlProviderRef.validateSilent();
      this.urlProviderRef.reset();
    }
  }

  getUrlValidationMode(): VeeValidateProviderMode {
    return this.urlProviderRef && this.urlProviderRef.flags.validated ? VeeValidateProviderMode.Eager : VeeValidateProviderMode.Passive;
  }

  private async canSave(): Promise<boolean> {
    if (this.formObserverRef) {
      await this.formObserverRef.validate();
    }

    return !paymentsModule.isSavingStorePolicy
      && !!this.formObserverRef
      && this.formObserverRef.flags.valid
      && this.formObserverRef.flags.changed;
  }

  private async onSaveFailed() {
    // Reset enabled state if user tried to disable it (since disabling automatically saves and save button/error get hidden)
    if (!this.enabled && this.initialValue.enabled) {
      await this.resetEnabledInput();
    }
  }

  async saveStorePolicy() {
    if (!await this.canSave()) {
      return;
    }

    // We only need to update this state if the button is visible (policy = enabled)
    if (this.enabled) {
      this.isValidForProgressButton = false;
    }

    const payload: AddUpdateStorePolicyPayload = {
      accountId: this.accountId,
      enabled: this.enabled,
      url: this.url
    };

    await paymentsModule.saveStorePolicy(payload);

    // We only need to update this state if the button is visible (policy = enabled)
    if (this.enabled) {
      this.isValidForProgressButton = true;
    }

    if (!paymentsModule.didSaveStorePolicyFail) {
      APP_UTILITIES.showToastMessage(this.policySavedSuccess, ToastType.Success);
    } else {
      await this.onSaveFailed();
    }
  }

}
