import { render, staticRenderFns } from "./ChangeFeeModal.vue?vue&type=template&id=3deca15a&scoped=true&"
import script from "./ChangeFeeModal.vue?vue&type=script&lang=ts&"
export * from "./ChangeFeeModal.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeFeeModal.vue?vue&type=style&index=0&id=3deca15a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3deca15a",
  null
  
)

export default component.exports