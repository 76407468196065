































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CardComponent from '@/commoncomponents/CardComponent.vue';

import paymentsModule from '@/store/modules/Payments/module';
import ToggleSwitch from '@/commoncomponents/ToggleSwitch.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { ToastType } from '@/Model/toastType';
import {
  Discount,
  DiscountType,
  AmountUnitType,
  TimeRangeType,
  EarlyRegistrationRule,
  MultiParticipantRule
} from '@/Model/payments/types';
import UIkit from 'uikit';

@Component({
  components: {
    CardComponent,
    ToggleSwitch
  }
})
export default class DiscountTableRow extends Vue {
  @Prop({ required: true }) discount!: Discount;
  @Prop({ required: true }) index!: number;

  discountType = DiscountType;
  amountUnitType = AmountUnitType;
  timeRangeType = TimeRangeType;
  isToggleOpen = false;
  enabled = true;
  commonUtils = APP_UTILITIES;

  // Initial flag to indicate first render state
  private _firstRender = true;
  disableSwitch = false;

  get discountListComputed() {
    return paymentsModule.discountList;
  }

  beforeMount() {
    this.enabled = this.discountListComputed[this.index].isActive;
  }

  mounted() {
    this._firstRender = false;
    this.enabled = this.discount.isActive;
  }

  toggleAccordion(index: number) {
    UIkit.accordion(`#accordion-toggle-${index}`).toggle();
    this.isToggleOpen = !this.isToggleOpen;
  }



  get discountTableLabel(): string {
    if (this.discount.discountTypeId === DiscountType.EARLY_REGISTRATION) {
      const earlyRegistration = this.discount.rules as [EarlyRegistrationRule];
      return APP_UTILITIES.addSymbol(
        earlyRegistration[0].amount,
        this.discount.amountUnitType === AmountUnitType.FIXED_AMOUNT
      );
    }
    else {
      return 'Tiered';
    }
  }

  get multipleParticipantsRules(): MultiParticipantRule[] | null {
    if (this.discount.discountTypeId === DiscountType.MULTI_PARTICIPANT) {
      const multipleParticipants = this.discount.rules as MultiParticipantRule[];
      return multipleParticipants;
    }
    return null;
  }

  get earlyRegistrationSessionLabel(): string {
    const earlyRegistration = (this.discount.rules as [EarlyRegistrationRule])[0];
    return `If registering ${earlyRegistration.timeRangeUnit} ${APP_UTILITIES.earlyRegistrationDateLabel(earlyRegistration)} before session start date`;
  }

  @Watch('enabled')
  async onEnabledChanged() {
    if (
      !this._firstRender &&
      this.discount &&
      this.discount.isActive !== this.enabled
    ) {
      try {
        this.disableSwitch = true;
        // TODO: add request
        // await request
        // this.onStatusUpdated();
        APP_UTILITIES.showToastMessage(
          `Discount is ${!this.enabled
            ? 'inactive'
            : 'active'}`,
          ToastType.Success
        );
      }
      catch {
        APP_UTILITIES.showToastMessage('Unexpected Error!', ToastType.Error);
        this.enabled = !this.enabled;
      }
      finally {
        this.disableSwitch = false;
        const targetDiscount = JSON.parse(
          JSON.stringify(this.discountListComputed[this.index])
        );
        targetDiscount.isActive = this.enabled;
        // TODO: add logic to properly set the discount
        // paymentsModule.updateDiscountList(targetDiscount);
      }
    }
  }

  handleEdit(targetDiscountIndex: number) {
    // TODO: add logic to properly set the discount
    // paymentsModule.setCurrentDiscountIndex(targetDiscountIndex);
    paymentsModule.setIsConfiguratioDiscountMenuOpen(true);
  }
}
