

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CardComponent extends Vue {
  @Prop({ default: false }) shouldExpandFullWidth !: boolean;
  @Prop({ default: false }) disableMarginTop !: boolean;
}
