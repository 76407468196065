


























































































































































import {
  Component,
  Prop,
  PropSync,
  Ref,
  Vue,
  Watch
} from "vue-property-decorator";
import { ScreenText } from "@/lang/ScreenText";
import OffCanvas from "@/commoncomponents/OffCanvas.vue";
import DropdownList from "@/ui-components/dropdownListBx/DropdownList.vue";
import { CurrencyInput, CurrencyInputOptions } from "vue-currency-input";
import APP_CONST from "@/constants/AppConst";
import APP_UTILITIES from "@/utilities/commonFunctions";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { VeeValidateProviderMode } from "@/Model/forms/types";
import {
  CustomCheckoutAutoEnrollOptions,
  CustomCheckoutOption
} from "@/Model/payments/types";
import { ProviderInstance } from "vee-validate/dist/types/types";
import ProgressButton from "@/commoncomponents/progressButton/progressButton";
import ChangeCustomPaymentModal from "@/popupcomponents/payments/ChangeCustomPaymentModal.vue";
import { checkoutNameRequiredValidator } from "@/validators/payments/validators";
import {
  createCustomCheckout,
  updateCustomCheckout
} from "@/services/payments/api";
import { ToastType } from "@/Model/toastType";

@Component({
  components: {
    OffCanvas,
    DropdownList,
    CurrencyInput,
    ValidationObserver,
    ValidationProvider,
    ProgressButton,
    ChangeCustomPaymentModal
  }
})
export default class CustomCheckout extends Vue {
  @Prop({ required: true }) checkoutOption: CustomCheckoutOption | undefined; // Type annotation for clarity

  @PropSync("isCustomizeCheckoutVisible", { type: Boolean, required: true })
  isCustomizeCheckoutVisibleSync!: boolean;
  @Ref("formObserverRef") readonly formObserverRef?: InstanceType<
    typeof ValidationObserver
  >;
  @Ref("nameObserverRef") readonly nameObserverRef?: ProviderInstance;

  readonly screenText = new ScreenText();
  readonly currencyOptions: CurrencyInputOptions = APP_CONST.CURRENCY_OPTIONS;
  readonly nameText = this.screenText.getScreenText("LABEL_NAME").toLowerCase();

  isValidationForProgress = false;
  autoEnrollOptions = CustomCheckoutAutoEnrollOptions;
  validationMode = VeeValidateProviderMode;
  isChangeCustomPaymentModalVisible = false;
  isSavingCustomPayment = false;

  name: string = "";
  description: string = "";
  checkoutType: CustomCheckoutAutoEnrollOptions =
    CustomCheckoutAutoEnrollOptions.Enroll;

  get saveButtonText(): string {
    return this.screenText.getScreenText("BTN_SAVE");
  }

  created() {
    this.onCreated();
    if (this.checkoutOption) {
      this.name = this.checkoutOption.name;
      this.description = this.checkoutOption.instructions;
      this.checkoutType = this.checkoutOption.autoEnrollStatusId;
    }
  }

  onCreated() {
    this.registerValidators();
  }

  onCancel() {
    this.onClose();
    this.isCustomizeCheckoutVisibleSync = false;
  }

  onClose() {
    if (!this.checkoutOption) {
      this.name = "";
      this.description = "";
      this.resetInputs();
    } else {
      this.name = this.checkoutOption.name;
      this.description = this.checkoutOption.instructions;
    }
  }

  @Watch("checkoutOption")
  onCheckoutOptionChange() {
    if (this.checkoutOption) {
      this.name = this.checkoutOption.name;
      this.description = this.checkoutOption.instructions;
      this.checkoutType = this.checkoutOption.autoEnrollStatusId;
    }
  }

  private async canSave(): Promise<boolean> {
    if (this.formObserverRef) {
      await this.formObserverRef.validate();
    }

    return !!this.formObserverRef && this.formObserverRef.flags.valid;
  }

  private resetInputs() {
    if (this.formObserverRef) {
      this.formObserverRef.reset();
    }
  }

  async onSave() {
    if (!(await this.canSave())) {
      return;
    }

    this.isValidationForProgress = false;

    try {
      this.isSavingCustomPayment = true;
      if (this.checkoutOption) {
        // Edit entry
        await updateCustomCheckout({
          name: this.name,
          instructions: this.description,
          autoEnrollStatusId: this.checkoutType,
          id: this.checkoutOption.id
        });
      } else {
        // Create entry
        await createCustomCheckout({
          name: this.name,
          instructions: this.description,
          autoEnrollStatusId: this.checkoutType,
          accountID: APP_UTILITIES.getAccountId(),
          isEnabled: true
        });
      }
      APP_UTILITIES.showToastMessage(
        "Custom checkout saved",
        ToastType.Success
      );
    } catch {
      APP_UTILITIES.showToastMessage("Unexpected Error!", ToastType.Error);
    } finally {
      this.OnSaveCustomPayment();
      this.isSavingCustomPayment = false;
    }

    this.isValidationForProgress = false;
    this.isCustomizeCheckoutVisibleSync = false;
  }

  closeChangeCustomPaymentModal(): void {
    this.isChangeCustomPaymentModalVisible = false;
  }

  confirmChangeCustomPayment(): void {
    this.closeChangeCustomPaymentModal();
    this.onSave();
  }

  OnSaveCustomPayment() {
    this.$emit("OnSaveCustomPayment");
  }

  beforeOnSave() {
    if (this.checkoutOption) {
      if (
        (this.formObserverRef && this.formObserverRef.flags.changed) ||
        this.checkoutOption.autoEnrollStatusId !== this.checkoutType
      ) {
        this.isChangeCustomPaymentModalVisible = true;
      } else {
        this.onCancel();
      }
    } else {
      this.onSave();
    }
  }

  private registerValidators() {
    checkoutNameRequiredValidator(
      this.screenText
        .getScreenText("REQUIRED_INPUT_FIELD_ERROR")
        .replace("{field}", this.nameText)
    );
  }
}
