var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"discount-configuration-wrapper"},[_c('div',{staticClass:"form-group uk-margin-small-bottom"},[_c('label',{staticClass:"uk-margin-remove",attrs:{"for":"description","data-testid":"checkout-description"}},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText("LABEL_DISCOUNT_EARLY_REGISTRATION"))+" ")]),_c('div',{staticClass:"subheader"},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText( "LABEL_DISCOUNT_EARLY_REGISTRATION_SUBHEADER" ))+" ")])]),_c('div',{staticClass:"discount-configuration-input-container"},[_c('div',{staticClass:"form-group w-18"},[_c('label',{staticClass:"uk-margin-small-bottom",attrs:{"for":"tax-rate-select","data-testid":"tax-rate"}},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText("LABEL_DISCOUNT_EARLY_DISCOUNT_ENDS"))),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]),_c('ValidationProvider',{ref:"percentInputObserverRef",attrs:{"rules":"required","mode":_vm.validationMode.Eager},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.earlyDiscountEndsSync),expression:"earlyDiscountEndsSync"}],attrs:{"id":"discount-ends","type":"number","min":"1"},domProps:{"value":(_vm.earlyDiscountEndsSync)},on:{"input":function($event){if($event.target.composing){ return; }_vm.earlyDiscountEndsSync=$event.target.value}}}),_c('span',{staticClass:"form-field-error",style:({ visibility: validated && invalid ? 'visible' : 'hidden' }),attrs:{"data-testid":"discount-ends-error"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group w-60"},[_c('ValidationProvider',[_c('DropdownList',{attrs:{"dropdown":_vm.discountRuleListDropdown,"autoSelectionAllowed":false},on:{"onSelectionChange":_vm.onSelectSessionStart}})],1)],1),_c('div',{staticClass:"form-group w-18"},[_c('label',{staticClass:"uk-margin-small-bottom",attrs:{"for":"tax-rate-select","data-testid":"tax-rate"}},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText("LABEL_AMOUNT"))),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]),_c('ValidationProvider',{ref:"percentInputObserverRef",attrs:{"rules":"percent_input_required","mode":_vm.validationMode.Eager},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.earlyAmountValueSync),expression:"earlyAmountValueSync"}],staticClass:"input-with-symbol",class:{
            'input-with-symbol--percent':
              _vm.discountUnitAmountSync === _vm.discountType.PERCENT_RATE
          },attrs:{"id":"amount","type":"number","min":"1","max":_vm.discountUnitAmountSync === _vm.discountType.PERCENT_RATE
              ? 100
              : 9999999},domProps:{"value":(_vm.earlyAmountValueSync)},on:{"change":_vm.onAmountUpdated,"input":function($event){if($event.target.composing){ return; }_vm.earlyAmountValueSync=$event.target.value}}}),_c('span',{staticClass:"form-field-error",style:({ visibility: validated && invalid ? 'visible' : 'hidden' }),attrs:{"data-testid":"amount-error"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('span',{staticClass:"rules-container__inputs-decorator-content",class:_vm.discountUnitAmountSync === _vm.discountType.PERCENT_RATE
            ? 'rules-container__inputs-decorator-content--percent'
            : 'rules-container__inputs-decorator-content--currency'})],1)]),(_vm.earlyAmountValueSync)?_c('NotificationBox',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"fa-solid fa-circle-info info-circle-icon"})]},proxy:true},{key:"message",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.screenText .getScreenText("LABEL_DISCOUNT_EARLY_INFO_MESSAGE") .replace( "{amount}", _vm.discountUnitAmountSync === _vm.discountType.PERCENT_RATE ? (_vm.earlyAmountValueSync + "%") : ("$" + _vm.earlyAmountValueSync) )))])]},proxy:true}],null,false,789220507)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }