




























import { Vue, Component, PropSync } from 'vue-property-decorator';
import BaseModal from '@/popupcomponents/BaseModal.vue';
import { ScreenText } from '@/lang/ScreenText';

@Component({
  components: {
    BaseModal
  },
})
export default class ChangeFeeModal extends Vue {
  screenText = new ScreenText();

  @PropSync('isVisible') isVisibleSync!: boolean;

  onCancel() {
    this.$emit('closepopup');
  }

  onSave() {
    this.$emit('confirmChangeFee');
  }
}
